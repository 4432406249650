html,
body {
  height: 100%;
}

body {
  background-color: #fff;
  font-family: sans-serif;
  margin: 0;
  overflow: hidden;
}

.farSide {
  text-align: right;
}

#titleRow {
  white-space: nowrap;
  height: min-content;
}

#buttonRow {
  white-space: nowrap;
}

html[dir="RTL"] .farSide {
  text-align: left;
}

/* Buttons */
button {
  margin: 5px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: large;

  background-color: #eee;
  color: #000;
}

button.primary {
  border: 1px solid #dd4b39;
  background-color: #dd4b39;
  color: #fff;
}

button.ready {
  border: 1px solid #39dd54;
  background-color: #39dd54;
  color: #fff;
}

button.primary>img {
  opacity: 1;
}

button>img {
  opacity: 0.6;
  vertical-align: text-bottom;
}

button:hover>img {
  opacity: 1;
}

button:active {
  border: 1px solid #888 !important;
}

button:hover {
  box-shadow: 2px 2px 5px #888;
}

button.disabled:hover>img {
  opacity: 0.6;
}

button.disabled {
  display: none;
}

button.notext {
  font-size: 10%;
}

h1 {
  font-weight: normal;
  font-size: 140%;
  margin-left: 5px;
  margin-right: 5px;
}

/* Tabs */
#tabRow>td {
  border: 1px solid #ccc;
  border-bottom: none;
}

td.tabon {
  border-bottom-color: #ddd !important;
  background-color: #ddd;
  padding: 5px 19px;
}

td.taboff {
  cursor: pointer;
  padding: 5px 19px;
}

td.taboff:hover {
  background-color: #eee;
}

td.tabmin {
  border-top-style: none !important;
  border-left-style: none !important;
  border-right-style: none !important;
}

td.tabmax {
  border-top-style: none !important;
  border-left-style: none !important;
  border-right-style: none !important;
  width: 99%;
  padding-left: 10px;
  padding-right: 10px;
  text-align: right;
}

html[dir=rtl] td.tabmax {
  text-align: left;
}

#tab_code {
  display: none;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  border: none;
}

td {
  padding: 0;
  vertical-align: top;
}

#buttonRow td {
  text-align: center;
  padding-top: 10px;
}

#titleRow td {
  font-size: larger;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-transform: uppercase;
  padding-left: 19px;
  vertical-align: middle;
}

#titleRow td.pageTitle {
  font-size: 2rem;
  font-family: 'Oswald', sans-serif;
}

#titleRow {
  color: #fff;
  background-color: #66BB6A;
  box-shadow: 0px 3px 5px #888;
}

.content {
  visibility: hidden;
  margin: 0;
  padding: 1ex;
  position: absolute;
  direction: ltr;
}

pre.content {
  border: 1px solid #ccc;
  overflow: scroll;
}

#content_blocks {
  padding: 0;
}

.blocklySvg {
  border-top: none !important;
}

#content_xml {
  resize: none;
  outline: none;
  border: 1px solid #ccc;
  font-family: monospace;
  overflow: scroll;
}

.dropdown {
  vertical-align: top;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
}

/* Buttons */
button {
  padding: 1px 10px;
  margin: 1px 5px 5px;
  vertical-align: top;
}

span select {
  padding: 2px 5px 4px;
  margin: 1px 5px 5px;
  vertical-align: baseline;
}

.farSide select {
  font-size: smaller;
  margin: 10px;
  /* vertical-align: text-bottom; */
}

/* Sprited icons. */
.icon21 {
  height: 21px;
  width: 21px;
  background-image: url(./images/icons.png);
}

.trash {
  background-position: 0px 0px;
}

.link {
  background-position: -21px 0px;
}

.run {
  background-position: -42px 0px;
}

@media (max-width: 710px) {
  .tab_collapse {
    display: none;
  }

  #tab_code {
    display: table-cell;
  }
}

.color {
  color: hsla(hue, saturation, lightness, alpha);
  color: #83019d;
}

.hide {
  display: none;
}

.blocklyTreeSelected .blocklyTreeLabel {
  text-shadow: 1px 1px 3px black;
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 9999999999;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

.modal-sheet {
  position: fixed;
  z-index: 99999999999;
  bottom: 0;
  background-color: #fefefe;
  width: 100%;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s
}

.modal-sheet .modal-body {
  text-align: left;
  width: 100%;
}

.closeT {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closeText:hover,
.closeText:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}


/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }

  to {
    bottom: 0;
    opacity: 1
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }

  to {
    bottom: 0;
    opacity: 1
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 1px 16px 1px 32px;
  background-color: #5cb85c;
  color: white;
  text-transform: uppercase;
}

.modal-body {
  padding: 1em 2em;
  text-align: center;
}

.modal-footer {
  padding: 8px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-footer a {
  font-weight: bold;
  text-decoration: none;
  color: white;
  text-shadow: 1px 1px 3px black;
}

.modal-footer a:hover {
  text-decoration: underline;
}


.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

#content_editor {
  border-top: 1px solid black;
}

#response span {
  font-size: 48px;
}